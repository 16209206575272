import React, { Component } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

class DateRangeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: new Date().toISOString().split('T')[0],
      toDate: new Date(Date.now() + 86400000).toISOString().split('T')[0],
      isCalendarVisible: false,
      selecting: 'from',
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear()
    };
  }

  toggleCalendar = () => {
    this.setState(prevState => ({
      isCalendarVisible: !prevState.isCalendarVisible
    }));
  }

  handleDateClick = (date) => {
    if (this.state.selecting === 'from') {
      this.setState({
        fromDate: date,
        selecting: 'to'
      });
    } else {
      if (new Date(date) >= new Date(this.state.fromDate)) {
        this.setState({
          toDate: date,
          selecting: 'from',
          isCalendarVisible: false
        });
      } else {
        alert('To date must be after from date');
      }
    }
  }

  changeMonth = (increment) => {
    this.setState(prevState => {
      let newMonth = prevState.currentMonth + increment;
      let newYear = prevState.currentYear;

      if (newMonth > 11) {
        newMonth = 0;
        newYear += 1;
      } else if (newMonth < 0) {
        newMonth = 11;
        newYear -= 1;
      }

      return {
        currentMonth: newMonth,
        currentYear: newYear
      };
    });
  }

  changeYear = (increment) => {
    this.setState(prevState => ({
      currentYear: prevState.currentYear + increment
    }));
  }

  generateCalendarDays = () => {
    const { currentMonth, currentYear } = this.state;
    const firstDay = new Date(currentYear, currentMonth, 1);
    const lastDay = new Date(currentYear, currentMonth + 1, 0);
    const days = [];
    
    // Add empty cells for days before the first of the month
    for (let i = 0; i < firstDay.getDay(); i++) {
      days.push(<td key={`empty-${i}`}></td>);
    }
    
    // Add days of the month
    for (let i = 1; i <= lastDay.getDate(); i++) {
      const date = new Date(currentYear, currentMonth, i);
      const dateString = date.toISOString().split('T')[0];
      const isSelected = dateString === this.state.fromDate || dateString === this.state.toDate;
      const isInRange = dateString > this.state.fromDate && dateString < this.state.toDate;
      
      days.push(
        <td 
          key={i}
          className={`text-center ${isSelected ? 'bg-primary text-white' : ''} 
                     ${isInRange ? 'bg-light' : ''}`}
          onClick={() => this.handleDateClick(dateString)}
          style={{ cursor: 'pointer', padding: '8px' }}
        >
          {i}
        </td>
      );
    }
    
    return days;
  }

  render() {
    const { fromDate, toDate, isCalendarVisible, currentMonth, currentYear } = this.state;
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 
                   'July', 'August', 'September', 'October', 'November', 'December'];
    
    return (
      <div className="container mt-4">
        <button 
          className="btn btn-primary mb-3"
          onClick={this.toggleCalendar}
        >
          Select Dates
        </button>

        {isCalendarVisible && (
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <button 
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={() => this.changeMonth(-1)}
                  >
                    ◀
                  </button>
                  <span className="mx-2">
                    {months[currentMonth]} {currentYear}
                  </span>
                  <button 
                    className="btn btn-sm btn-outline-secondary ms-2"
                    onClick={() => this.changeMonth(1)}
                  >
                    ▶
                  </button>
                </div>
                <div>
                  <button 
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={() => this.changeYear(-1)}
                  >
                    ◀◀
                  </button>
                  <button 
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => this.changeYear(1)}
                  >
                    ▶▶
                  </button>
                </div>
              </div>

              <table className="table table-bordered">
                <thead>
                  <tr>
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                      <th key={day} className="text-center">{day}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array(6).fill(null).map((_, weekIndex) => (
                    <tr key={weekIndex}>
                      {this.generateCalendarDays()
                        .slice(weekIndex * 7, (weekIndex + 1) * 7)}
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="d-flex justify-content-between align-items-center">
                <div className="text-muted small">
                  {this.state.selecting === 'from' ? 'Select start date' : 'Select end date'}
                </div>
                <div>
                  Selected: {fromDate} - {toDate}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DateRangeSelector;