import React from "react";
import {
    Card,
    CardBody,
    Input,
    Accordion,
    AccordionItem,
    AccordionBody,
    AccordionHeader,
} from "reactstrap";
import Footer from "../Components/Footer"
import withRouter from "../Components/withRouter"
import axios from "axios";
import { baseUrl } from "../index"
import SelectSearch from "react-select-search";
import "../App.css"
import MapComponent from "../Components/SearchMap";
import { amenityCalculator, overallFilter, rateCalculator, roomTypeCalculator } from "../Utils/searchFilters";
import { pink, red } from "@mui/material/colors";
import { Helmet } from "react-helmet";
import faq from "../Assets/faq.png";
import randomColor from "randomcolor";
import MyMap from "../Components/SearchMap";

class SearchPage extends React.Component {
    constructor() {
        super()
        this.state = {
            fromDate: "",
            toDate: "",
            searchInput: "",
            otherSearch: "",
            amenity: "",
            allHostels: [],
            filteredHostels: [],
            roomTypeFilter: [],
            amenityFilter: [],
            ratingFilter: [],
            searchText: "", // New: To store search_text
            faq: "",
            open: false,
            faqQuestions: [],
            faqAnswers: [],
            priceFilterValue: "",
            amenityFilterValue: "",
            ratingFilterValue: "",
            roomTypeFilterValue: "",
            loading: true,
        }
    }
        getDefaultDates = () => {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            
            const dayAfterTomorrow = new Date();
            dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    
            // Format dates as YYYY-MM-DD
            const formatDate = (date) => {
                return date.toISOString().split('T')[0];
            };
            // console.log({date:{tomorrow,dayAfterTomorrow}});
            return {
                fromDate: formatDate(tomorrow),
                toDate: formatDate(dayAfterTomorrow)
            };}
    

    componentDidMount() {
     const { params, searchParams } = this.props.router;
    let city, fromDate, toDate, amenity, otherSearch;
    // URL pattern /search/:location
    if (params.location) {
        city = params.location;
    } else {
        city = searchParams.get("city");
    }
    fromDate = searchParams.get("from");
    toDate = searchParams.get("to");
    amenity = searchParams.get("amenity");
    otherSearch = searchParams.get("otherSearch");

    // If dates are not provided or check-in date is in the past, use default dates
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const needsDefaultDates = !fromDate || !toDate || (new Date(fromDate) < today);
    
    if (needsDefaultDates) {
        const defaultDates = this.getDefaultDates();
        fromDate = defaultDates.fromDate;
        toDate = defaultDates.toDate;
            
        // Update URL with the new dates
        // const currentUrl = new URL(window.location.href);
        // currentUrl.pathname = `search/best-hostels-in-${city}`;
        // // currentUrl.searchParams.set('from', fromDate);
        // // currentUrl.searchParams.set('to', toDate);
        // window.history.replaceState({}, '', currentUrl);
    }
    if (city && city.startsWith("hostels-in-")) {
        city = city.replace("hostels-in-", "");  // Remove duplicate prefix
    }    
    const newPath = `/search/hostels-in-${city}`;
    const currentUrl = new URL(window.location.href);
    if (currentUrl.pathname !== newPath) {
        window.history.replaceState({}, '', newPath);
    }
    // console.log({fromDate,toDate,amenity,city,otherSearch});
    this.setState({
        searchInput: city || "",
        amenity: amenity || "",
        fromDate,
        toDate,
        otherSearch: otherSearch || city
    }, this.fetchSearchResults);
    }

    componentDidUpdate(prevProps) {
        // Update meta tags when location or dates change
        const { params } = this.props.router;
        if (params.location !== prevProps.router.params.location ||
            params.dates !== prevProps.router.params.dates) {
            this.updateMetaTags();
        }
    }

    updateMetaTags = () => {
        const { params,searchParams } = this.props.router;
        const { searchText } = this.state;
        let city = params.location?params.location:searchParams.get("city");
        document.title = `Top Hostels in ${city} | The Hostel Co`;
        
        // Update meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute(
                "content",
                searchText || `Find the best hostels in ${params.location}. Book your stay today!`
            );
        }

        // Add schema markup for better SEO
        const schema = {
            "@context": "https://schema.org",
            "@type": "SearchResultsPage",
            "about": {
                "@type": "LodgingBusiness",
                "name": `Hostels in ${city}`,
                "location": {
                    "@type": "Place",
                    "name": city
                }
            }
        };

        const scriptTag = document.querySelector('#search-schema');
        if (scriptTag) {
            scriptTag.textContent = JSON.stringify(schema);
        } else {
            const script = document.createElement('script');
            script.id = 'search-schema';
            script.type = 'application/ld+json';
            script.textContent = JSON.stringify(schema);
            document.head.appendChild(script);
        }
    }


    fetchSearchResults = () => {
        console.log("inside fetchsearchresults")
        const { searchInput, amenity, fromDate, toDate, otherSearch } = this.state;
        
        let apiUrl = `${baseUrl}/inventory/search-results?`;
        apiUrl += `location=${searchInput}&amenity=${amenity}&checkInDate=${fromDate}&checkOutDate=${toDate}&otherSearch=${otherSearch}`;
        this.setState({ loading: true });

        axios.get(apiUrl)
            .then(response => {
                const hostels = response.data.hostels || [];
                console.log("hostel response",hostels)
                const seodata = response.data.seo_data || {};
                const mapData = response.data.mapData || [];
                const mapCenter = response.data.mapCenter || [];
                const mapZoom = response.data.mapZoom || 10;

                // Parse FAQ data
                const faqData = seodata.faq || '';
                const faqPairs = faqData.split('Q:').filter(pair => pair.trim() !== '');
                const questions = [];
                const answers = [];

                faqPairs.forEach(pair => {
                    const [question, answer] = pair.split('A:');
                    if (question && answer) {
                        const cleanedQuestion = question.replace(/;/g, '').trim();
                        const cleanedAnswer = answer.replace(/;/g, '').trim();
                        questions.push(cleanedQuestion);
                        answers.push(cleanedAnswer);
                    }
                });

                if (!hostels.length) {
                    this.setState({
                        faqQuestions: questions,
                        faqAnswers: answers,
                        searchText: seodata.search_text || "",
                        loading: false
                    });
                    return;
                }

                const roomTypeFilter = roomTypeCalculator(hostels);
                const amenityFilter = amenityCalculator(hostels);
                const ratingFilter = rateCalculator(hostels);

                this.setState({
                    allHostels: hostels,
                    filteredHostels: hostels,
                    mapData,
                    mapCenter,
                    mapZoom,
                    roomTypeFilter,
                    amenityFilter,
                    ratingFilter,
                    searchText: seodata.search_text || "",
                    faqQuestions: questions,
                    faqAnswers: answers,
                    loading: false
                }, this.updateMetaTags);
            })
            .catch(err => {
                this.setState({ loading: false });
                console.log(err.message);
            });
    }

    // generateUniqueColors = (count) => {
    //     const colors = new Set();
    //     while (colors.size < count) {
    //       colors.add(
    //         randomColor({
    //           luminosity: "light"
    //         })
    //       );
    //     }
    //     return Array.from(colors);
    //   };
    generateUniqueColors = (count) => {
        const predefinedColors = ["#2B67F6", "#27AE60", "#EB5757"]; // The specific colors as per figma file
        const colors = new Set(predefinedColors); // Initialize with predefined colors

        // additional colors if needed 
        while (colors.size < count) {
            colors.add(
                randomColor({
                    luminosity: "dark",
                })
            );
        }

        return Array.from(colors).slice(0, count);
    };


    render() {
        const { loading, faqQuestions, faqAnswers } = this.state

        const onHostel = (id) => {
            const { fromDate, toDate } = this.state
            window.location.href = `/hostel?hostel=${id}&from=${fromDate}&to=${toDate}`
        }

        const onChange = (e) => {
            const { name, value } = e.target;
            const { allHostels, priceFilterValue, ratingFilterValue, roomTypeFilterValue, amenityFilterValue, searchText, faq } = this.state;

            this.setState({ [name]: value }, () => {
                const Filters = {
                    priceFilterValue: name === 'priceFilterValue' ? value : priceFilterValue,
                    ratingFilterValue: name === 'ratingFilterValue' ? value : ratingFilterValue,
                    roomTypeFilterValue: name === 'roomTypeFilterValue' ? value : roomTypeFilterValue,
                    amenityFilterValue: name === 'amenityFilterValue' ? value : amenityFilterValue,
                };

                const filteredHostels = overallFilter(allHostels, Filters);
                this.setState({ filteredHostels });
            });
        };

        const toggle = (id) => {
            if (this.state.open === id) {
                this.setState({ open: "" });
            } else {
                this.setState({ open: id });
            }
        };


        if (loading) {
            return (
                <div className="loading-frame">
                    <div className="spinner"></div>
                    <span className="loading-text">Loading...</span>
                </div>
            );
        }
        const amenities = this.state.filteredHostels[0]?.amenities || [];
        const uniqueColors = this.generateUniqueColors(amenities.length);

        return (
            <div className=" search-whole-page">
                <Helmet>
                    <title>Best Hostels in {this.state.searchInput?.charAt(0).toUpperCase() + this.state.searchInput?.slice(1).toLowerCase()} | TheHostelCo for Solo & Party Trips</title>
                    <meta
                        name="description"
                        content={`Discover TheHostelCo's top hostels in ${this.state.searchInput?.charAt(0).toUpperCase() + this.state.searchInput?.slice(1).toLowerCase()}, perfect for solo travelers and party lovers. Explore Anjuna and North Goa's best backpacker spots. Book now!`}
                    />
                    <h1>Explore Top-Rated Hostels in {this.state.searchInput?.charAt(0).toUpperCase() + this.state.searchInput?.slice(1).toLowerCase()} with TheHostelCo</h1>
                </Helmet>
                <div className="pt-5 search-page-container">
                    <div className="main-container" style={{ textAlign: "left" }}>
                        <div className="text-black fw-bold h2 mb-3">
                            {this.state.filteredHostels.length}
                            {this.state.filteredHostels.length <= 1 ? " hostel" : " hostels"} found in {this.state.searchInput
                                ? this.state.searchInput.charAt(0).toUpperCase() + this.state.searchInput.slice(1).toLowerCase()
                                : this.state.otherSearch}
                        </div>
                        {this.state.allHostels.length === 0 &&
                            <div>
                                No hostel is available
                            </div>}
                    </div>

                    {/*  */}

                    {/*  */}
                    <div className=" flex items-center justify-center py-4">
                        <div className=" main-container d-flex gap-3 flex-wrap" style={{ justifyContent: "flex-start" }}>
                            <Input onChange={onChange} value={this.state.priceFilterValue} name="priceFilterValue" style={{ borderRadius: "0.5rem", width: "max-content", direction: "ltr" }} type="select">
                                <option value="">Price</option>
                                <option value="LH">Price - low to high</option>
                                <option value="HL">Price - high to low</option>
                            </Input>
                            <Input onChange={onChange} value={this.state.amenityFilterValue} name="amenityFilterValue" style={{ borderRadius: "0.5rem", width: "max-content" }} type="select">
                                <option value="">Amenities</option>
                                {this.state.amenityFilter.map(eachAmenity => {
                                    return (
                                        <option value={eachAmenity} key={eachAmenity}>{eachAmenity}</option>
                                    )
                                })}
                            </Input>
                            <Input onChange={onChange} value={this.state.ratingFilterValue} name="ratingFilterValue" style={{ borderRadius: "0.5rem", width: "max-content" }} type="select">
                                <option value="">Rating</option>
                                {this.state.ratingFilter.map(eachRating => {
                                    return (
                                        <option value={eachRating} key={eachRating}>{eachRating}</option>
                                    )
                                })}
                            </Input>
                            <Input onChange={onChange} value={this.state.roomTypeFilterValue} name="roomTypeFilterValue" style={{ borderRadius: "0.5rem", width: "max-content" }} type="select">
                                <option value="">Room Type</option>
                                {this.state.roomTypeFilter.map(eachRoomType => {
                                    return (
                                        <option value={eachRoomType} key={eachRoomType}>{eachRoomType}</option>
                                    )
                                })}
                            </Input>
                        </div>
                    </div>

                    <div className=" main-container d-flex row row-cols-1 row-cols-lg-2 g-3 mb-3" style={{ width: "92%" }}>
                        <div className="col"
                            style={{
                                overflowY: "auto",
                                maxHeight: "28rem",
                                padding: "0px 10px",
                                scrollbarWidth: "thin",    //  Thin scrollbar
                                scrollbarColor: "#888 #f1f1f1"
                            }}
                        >
                            {this.state.filteredHostels.map(eachCard => {
                                return (
                                    <div key={eachCard} className="col">
                                        <Card onClick={() => onHostel(eachCard.id)} style={{ cursor: "pointer", borderRadius: "1rem", overflow: "hidden", alignItems: "flex-start", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.10)", marginTop: "0.5rem" }} className="feature-card mb-3">
                                            <CardBody style={{width:"100%" , padding:"1",display:"flex",alignItems:"start" ,justifyContent:"" }}>
                                                <div className="d-flex" style={{justifyContent:"" , gap:"3rem",width:"100%"}}>
                                                    <div className="search-card-image" style={{ width: "300px", height: "11rem", padding: "0 0px" , width:'30%' }}>
                                                        <img src={eachCard.thumbnail} style={{ borderRadius: "1rem", marginBottom: "4rem", height: "100%", width: "100%", objectFit: "cover" }} alt="HostelCo" />
                                                        <div style={{ borderRadius: "1rem", marginTop: "-6.5rem", width: "max-content", zIndex: "4", backgroundColor: "#251313", opacity: '0.9' }} className="bg-tertiary-o px-2 py-1 ms-2 text-white">Rs. {eachCard?.minimumAvgPrice}</div>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-between">
                                                        <div>
                                                            <div className="text-black fw-bold" style={{ fontFamily: "Roboto", fontSize: "1.5rem" }} >{eachCard.hostel_name}</div>
                                                            <div className="text-black mb-2" style={{ fontFamily: "Roboto", fontSize: "1rem" }} >{eachCard.location}</div>
                                                            <div className="d-flex gap-2 flex-wrap mb-5">
                                                                {eachCard.amenities.map((eachAmenity, index) => {
                                                                    return (
                                                                        <div className="px-2 py-1 rounded" style={{ backgroundColor: `${uniqueColors[index]}2A`, fontSize: "0.75rem", color: uniqueColors[index], boxShadow: "none" }}>{eachAmenity}</div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                        <div className="text-white px-3 py-1" style={{ backgroundColor: "#2D7912", borderRadius: "2rem", width: "max-content", boxShadow: "0px 4px 4px 0px #00000040", }}>
                                                            <i className="bi bi-star-fill"></i> {eachCard.rating}
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                )
                            })}
                        </div>
                        {this.state.filteredHostels.length > 0 && (
                            <div className="col d-flex flex-grow-1">
                                <MyMap mapData={this.state.mapData} mapCenter={this.state.mapCenter} mapZoom={this.state.mapZoom} /> {/* Add the MyMap component here */}
                            </div>
                        )}
                    </div>
                </div>
                <div className="bg-dark-o search-page-container">
                    <div className="main-container d-flex gap-3 justify-content-between m-5 flex-wrap">
                        <div>
                            <div className=" h4 text-white my-3 fw-bold">About</div>
                            <div className="text-white mb-5">
                                {this.state.searchText}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white search-page-container">
                    <div className="main-container py-5">
                        <div className="heading-font-2 text-red h2 text-center mb-5">
                            Frequently asked questions
                        </div>
                        <div className="faq-div">
                            <Accordion open={this.state.open} toggle={toggle}>
                                {faqQuestions && faqQuestions.length > 0 ? (
                                    faqQuestions.map((question, index) => (
                                        <AccordionItem key={index} className="mb-3 bg-transparent border-none">
                                            <AccordionHeader
                                                targetId={index + 1}
                                                className="accordion-button-none"
                                            >
                                                <p className="me-3 text-dark">{question}</p>
                                                <i
                                                    className="bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1"
                                                    style={{ opacity: 0.5 }}
                                                ></i>
                                            </AccordionHeader>
                                            <AccordionBody
                                                className="text-dark"
                                                accordionId={index + 1}
                                            >
                                                {faqAnswers[index]}
                                            </AccordionBody>
                                        </AccordionItem>
                                    ))
                                ) : (
                                    <div>No FAQs available</div>
                                )}
                            </Accordion>
                            <div className='faq-img-div'>
                                <img src={faq} alt='faq' className='faq-img' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-page-container">
                    <Footer />
                </div>
            </div>
        )
    }
}

export default withRouter(SearchPage)
