import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import SelectSearch from 'react-select-search';

class SearchBarWithOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: props.check || "",
            isCalendarVisible: false,
            fromDate: props.fromDate || new Date(),
            toDate: props.toDate || new Date(Date.now() + 86400000),
            searchInput: props.searchInput || '',
            hostelId: props.hostelId || null,
            otherSearch: props.otherSearch || []
        };
    }

    handleSubmit = () => {
        const { fromDate, toDate, searchInput, hostelId, otherSearch } = this.state;
        if(!hostelId){return}
        // console.log("Submitting search:", { fromDate, toDate, searchInput, hostelId, otherSearch });
        var temp = otherSearch.join(",");
        if(hostelId.hostelid==="")
          {
            window.location.href = `/search?city=${searchInput}&from=${new Date(fromDate).toISOString().split("T")[0]}&to=${new Date(toDate).toISOString().split("T")[0]}&otherSearch=${temp}`;
          }
          else
          {
            window.location.href = `/hostel?hostel=${hostelId.hostelid}&from=${new Date(fromDate).toISOString().split("T")[0]}&to=${new Date(toDate).toISOString().split("T")[0]}&otherSearch=${temp}`;
          }
    };

    toggleCalendar = () => {
        this.setState(prevState => ({
            isCalendarVisible: !prevState.isCalendarVisible
        }));
    }

    onChange = (dates) => {
        const [start, end] = dates;
        this.setState({
            fromDate: start,
            toDate: end,
        });
    };

    formatDate = (date) => {
        if (!date) return '';
        return date.toISOString().split('T')[0];
    };

    handleSearchChange = (value, hostelid) => {
        if (!hostelid) return;

        this.setState(prevState => ({
            searchInput: hostelid.type !== "word" ? value : "",
            hostelId: hostelid,
            check: value,
            otherSearch: [...prevState.otherSearch, value]
        }));
    }

    render() {
        const {  isCalendarVisible, fromDate, toDate } = this.state;

        return (
            <div className="mobile-searchbar position-relative justify-content-center align-items-center flex-column" 
                style={{height: "6rem", width: "97%", marginTop: "-7.5rem"}}
            >
                <div style={{ backgroundColor: "#f9f9f9", width: "80%",justifyContent:"space-between" }} 
                    className="d-flex p-1 shadow-sm rounded-pill">
                    
                    <div className="d-flex align-items-center" style={{paddingLeft:"1rem"}}>
                        {/* <Button onClick={this.handleSubmit} size="sm" className="book-button d-flex d-md-none"
                            style={{ backgroundColor: "#f9f9f9", zIndex: "2" }}>
                            <i className="bi bi-search" style={{ color: "#FD590C", fontSize: "x-large" }}></i>
                        </Button> */}

                        <div className="d-flex flex-column flex-grow-1">
                            <div  style={{width:"8rem"}}>
                                <SelectSearch
                                    options={this.props.allProperties.sort((a, b) => {
                                        const categoryA = a.type === 'location' ? 1 : a.type === 'hostel' ? 2 : 3;
                                        const categoryB = b.type === 'location' ? 1 : b.type === 'hostel' ? 2 : 3;
                                        return categoryA - categoryB;
                                    }) || []}
                                    name="searchInput"
                                    search
                                    placeholder="Where do you prefer to go?" 
                                    onChange={this.handleSearchChange}
                                    filterOptions={[(options, query) => {
                                        if (query.length < 2) return [];
                                        return options.filter(option =>
                                            option.name.toLowerCase().startsWith(query.toLowerCase())
                                        );
                                    }]}
                                />
                            </div>

                            <div className="d-flex align-items-center" style={{paddingLeft:"1rem"}}>
                                <Input
                                    min={new Date().toISOString().split("T")[0]}
                                    style={{ width: "6rem", padding: 0 , fontSize: "small", position: "relative", zIndex: "2"}}
                                    className="border-0 bg-light"
                                    value={this.formatDate(fromDate)}
                                    placeholder='From'
                                    name='fromDate'
                                    type='date'
                                    onChange={(e) => this.setState({ fromDate: new Date(e.target.value) })}
                                />
                                <Input
                                    min={this.formatDate(fromDate)}
                                    style={{ width: "6rem", padding: 0, fontSize: "small" }}
                                    className="border-0 bg-light"
                                    value={this.formatDate(toDate)}
                                    placeholder='To'
                                    name='toDate'
                                    type='date'
                                    onChange={(e) => this.setState({ toDate: new Date(e.target.value) })}
                                />
                               
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                        <Button onClick={this.handleSubmit} size="sm" className="book-button d-flex d-md-none"
                            style={{ backgroundColor: "#f9f9f9", zIndex: "2" }}>
                            <i className="bi bi-search" style={{ color: "#FD590C", fontSize: "x-large" }}></i>
                        </Button>
                </div>

                
            </div>
        );
    }
}

export default SearchBarWithOptions;
