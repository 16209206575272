import React from "react";
import {
  Button,
  Card,
  CardBody,
  Input,
  Accordion,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
} from "reactstrap";
import "react-select-search/style.css";
import SelectSearch from "react-select-search";
import Discover from "../Assets/DISCOVER2.jpg";
import Footer from "../Components/Footer";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { baseUrl } from "../index";
import "../App.css";
import Slider from "react-slick";
import faq from "../Assets/faq.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DateRangeSelector from "../Components/DateRangeSelector";
import SearchBarWithOptions from "../Components/SearchBarWithOptions";
import BookCardMobile from "../Components/BookCardMobile";

//SLIDER IMAGES IMPORT
import Anjuna from "./assets/AnujanFleaMarket.jpg"
import Arambol from "./assets/Arambol.png"
import TamilQuarter from "./assets/Tamil-Quarter.png"
import MysoreBelow from "./assets/MysoreBelow.png"
import Pondicherry from "./assets/Pondicherry.jpg"

import img1 from "./assets/imgs/BunkdExperiences.jpg"
import img2 from "./assets/imgs/BunknBrew.png"
import img3 from "./assets/imgs/Gaia_paintin.jpg"
import img4 from "./assets/imgs/GaiaTrek.jpg"
import img5 from "./assets/imgs/Roambay.jpg"
import img6 from "./assets/imgs/Unearth_Bonfire.jpg"
import img7 from "./assets/imgs/LifeSrtyleBibhitaki.jpg"
import img8 from "./assets/imgs/HostelExp2.jpg"
import img9 from "./assets/imgs/HostelExpAuroville.jpg"
import img10 from "./assets/imgs/HOT_Experiences.jpg"
import img11 from "./assets/imgs/YogaWorkoutJPG.jpg"

const SamplePrevArrow = (props) => {
  const {onClick} = props
  return (
    <div className="custom-arrow prev" onclick={onClick}>
    <span className="arrow-content">❮</span>
  </div>
  );
};

const SampleNextArrow = (props) => {
  const {onClick} = props 
  return (
    <div className="custom-arrow next" onClick={onClick}>
      <span className="arrow-content">❯</span>
    </div>
  );
};




const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  arrows: true,
  indicator: true,
  dots:true,
customPaging: function (i) {
  return (
    <div className="custom-dot">
      <span className="dot"></span>
    </div>
  );
},
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        variableWidth: true,

      },
    },
  ],
};
const smallDeviceSlider = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  arrows: true,
  indicator: true,
  variableWidth: true,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
 row:"1",
  customPaging: function (i) {
  return (
    <div className="custom-dot">
      <span className="dot"></span>
    </div>
  );
},
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};


class LandingPage extends React.Component {
  constructor() {
    super();
    this.state = {
      check:"",
      searchInput: "",
      hostelId:"",
      fromDate: "",
      toDate: "",
      nextDate: "",
      allHostels: [],
      allLocations: [],
      allWords: [],
      allProperties:[],
      otherSearch:[],
      open: "0",
      destImage:0,
     allImages : [
        {
          id: 1,
          title: "Anjuna",
          description: "",
          imageUrl: Anjuna,
          location: "Anjuna",
        },
        {
          id: 2,
          title: "Pondicherry",
          description: "",
          imageUrl: TamilQuarter,
          location: "Pondicherry",
        },
        {
          id: 3,
          title: "Arambol",
          description: "",
          imageUrl: Arambol,
          location: "Arambol",
        },
        {
          id: 4,
          title: "Mysore",
          description: "",
          imageUrl: MysoreBelow,
          location: "Mysore",
        },
        {
          id: 5,
          title: "Pondicherry",
          description: "",
          imageUrl: Pondicherry,
          location: "Pondicherry",
        },
      ]
      
    };
  }
  componentDidMount() {
    Promise.all([
      axios.get(`${baseUrl}/hostel/all-hostels`),
      axios.get(`${baseUrl}/hostel/hostel-location`),
      axios.get(`${baseUrl}/hostel/hostel-words`),
    ])
      .then(([hostelsResponse, locationsResponse, wordsResponse]) => {
        let allHostels = hostelsResponse.data;
        const allLocations = locationsResponse.data;
        const allWords = wordsResponse.data;
        // Combine hostels and locations into allProperties
        const hostelIds = allHostels?.map((r) => r?.id);
    
        axios
          .get(`${baseUrl}/hostel/rooms/minimum-price`, {
            params: { hostelIds: hostelIds.join(",") },
          })
          .then((res) => {
            const minPrice = res.data;
            // Updating hostels with the minimum price data
            const updatedHostels = allHostels?.map((hostel) => {
              const price = minPrice[hostel?.id] || 0;
              return {
                ...hostel,
                minimumRoomPrice: price,
              };
            });
    
            // Combine hostels and locations into allProperties
            const allProperties = [
              ...updatedHostels.map((hostel) => ({
                name: hostel.hostel_name,
                value: hostel.hostel_name,
                type: "hostel",
                hostelid: hostel.id,
              })),
              ...allLocations.map((location) => ({
                name: location.name,
                value: location.name,
                type: "location",
                hostelid: "",
              })),
              ...allWords.map((words) => ({
                name: words.words_Name,
                value: words.words_Name,
                type: "word",
                hostelid: "",
              })),
            ];
    
            console.log(updatedHostels);
            this.setState({
              allHostels: updatedHostels,
              allLocations,
              allProperties,
              allWords,
            });
          })
          .catch((err) => {
            console.log("Error fetching min price:", err.message);
          });
      })
      .catch((err) => {
        console.log("Error fetching data:", err.message);
      });
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const dayAfterTomorrow = new Date();
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
    this.setState({ fromDate: nextDay, toDate: dayAfterTomorrow });
  }

  render() {
    const onChange = (e) => {
      const { name, value } = e.target;
      if (name === "fromDate") {
        const nextDay = new Date(value);
        nextDay.setDate(nextDay.getDate() + 1);
        this.setState({ nextDate: nextDay.toISOString().split("T")[0] });
      }
      this.setState({ [name]: value });
    };
    const toggle = (id) => {
      if (this.state.open === id) {
        this.setState({ open: "" });
      } else {
        this.setState({ open: id });
      }
    };

    const nextImage =() =>{
     if(this.state.destImage < this.state.allHostels.length - 4 ){
      this.setState({destImage:this.state.destImage + 1})

     }else{
      this.setState({destImage:0})
     }
    }
    const prevImage =() =>{
      console.log(this.state.destImage)
      console.log(this.state.allHostels.length)
      if(this.state.destImage !== 0 ){
      this.setState({destImage:this.state.destImage - 1})
      }else{
        this.setState({destImage:9})
      }
    }
    const locationRedirect = (id)=>{
      const { fromDate, toDate } = this.state
      window.location.href = `/search?city=${id}&from=${new Date(fromDate).toISOString().split("T")[0]}&to=${new Date(toDate).toISOString().split("T")[0]}`;
    }
    const onSubmit = () => {
      const { searchInput,hostelId, fromDate, toDate, otherSearch } = this.state
      var temp = otherSearch.join(",");
      if(hostelId.hostelid==="")
        {
          window.location.href = `/search?city=${searchInput}&from=${new Date(fromDate).toISOString().split("T")[0]}&to=${new Date(toDate).toISOString().split("T")[0]}&otherSearch=${temp}`;
        }
        else
        {
          window.location.href = `/hostel?hostel=${hostelId.hostelid}&from=${new Date(fromDate).toISOString().split("T")[0]}&to=${new Date(toDate).toISOString().split("T")[0]}&otherSearch=${temp}`;
        }
    };
    const hostelRedirect = (id) => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      window.location.href = `/hostel?hostel=${id}&from=${
        today.toISOString().split("T")[0]
      }&to=${tomorrow.toISOString().split("T")[0]}`;
    };

    const searchDisabled = !(
      this.state.check
    );

    const handleAmenityClick = (amenity) => {
      const today = new Date().toISOString().split("T")[0];
      const dayaftertomrw = new Date(
        new Date().setDate(new Date().getDate() + 1)
      )
        .toISOString()
        .split("T")[0];
      window.location.href = `/search?amenity=${amenity}&from=${today}&to=${dayaftertomrw}`;
    };

    return (
      <div className='bg-white flex flex-row gap-0' id="home">
  <div className="slider-main">
        <Slider {...settings}>  
            {this.state.allImages.map((eachLocation) => {
              return (
                <div
                  key={eachLocation.id} className="slider" style={{width:"100vw"}}>
                    <a onClick={() => {
                      let location = eachLocation.title.split(/\s+/);
                      locationRedirect(location[0]);
                    }} style={{ cursor: "pointer" }}>
                     <div className="slider-overlay"></div></a>
                  <img
                    src={eachLocation.imageUrl}
                    alt={`image-${eachLocation.id}`}
                    className="slider-image"
                  />
                  <div className="text-on-image-slider-div">
                  <h1 className="slider-image-heading">
                  {eachLocation.title}                  
                  </h1>
                  <p className="slider-image-para">
                  Every journey begins with a step,
                  and  
                  <br/>
                   this place is where memories are made.
                  </p>
                  </div>

                </div>
              );
            })}
          </Slider>
          </div>
          

{/* MOBILE SEARCH-BAR */}
<SearchBarWithOptions toDate={this.state.toDate}
  fromDate={this.state.fromDate}
  hostelId={this.state.hostelId}
  otherSearch={this.state.otherSearch}
  searchInput={this.state.searchInput}
  onChange={onChange}
  setDates={(from, to) => this.setState({ fromDate: from, toDate: to })}
  allProperties={this.state.allProperties}
  check={this.state.check}
  ></SearchBarWithOptions>


{/* search bar  */}
<div className='search-bar-main'> 
                <div className='search-bar' >
                    <div className='where' >
                      <div className='fw-bold d-flex gap-3 align-items-center  text-dark px-2 '> <i class="bi bi-geo-alt-fill fs-4"></i>Where</div>
                      <SelectSearch 
options={this.state.allProperties.sort((a, b) => {
  // Determine the category for each item (location, hostel, or other)
  const categoryA = a.type === 'location' ? 1 : a.type === 'hostel' ? 2 : 3;
  const categoryB = b.type === 'location' ? 1 : b.type === 'hostel' ? 2 : 3;

  // Sort by category (lower value comes first)
  return categoryA - categoryB;
})}
  name="searchInput"
  search
  onChange={(value,hostelid,type) => {
    console.log("Selected value:", hostelid.value);
    this.setState((prevState) => ({
      searchInput: hostelid.type!="word"?value:"", // Updates the state with the selected value
      hostelId: hostelid,
      check:value,
      otherSearch: [...prevState.otherSearch, value], // Updates the array immutably
    
    }));
    
  }}
  filterOptions={[(options, query) => {
    if (query.length < 2) {
      return []; // Show no options if input is less than 2 characters
    }
    return options.filter((option) =>
      option.name.toLowerCase().startsWith(query.toLowerCase()) // Check if the name starts with the query
    );
  }]}
  placeholder="Where do you prefer to go?"
  // className="custom-select-search"
/>
                    </div>
                    <div className='check-in'>
                      <div className='fw-bold d-flex gap-3 align-items-center  text-dark px-2   '> <i class="bi bi-calendar-fill fs-5 "></i> Check In</div>
                      <Input
                        min={new Date().toISOString().split("T")[0]}
                        className='border-0 custom-date-input'
                        onChange={onChange}
                        value={this.state.fromDate}
                        placeholder='From'
                        name='fromDate'
                        type='date'
                      />
                    </div>
                    <div className='check-out'>
                          <div className='fw-bold  d-flex gap-3 align-items-center text-dark px-2'> <i class="bi bi-calendar-fill fs-5"></i>   Check Out</div>
                          <Input
                            min={this.state.nextDate}
                            className='border-0 custom-date-input'
                            onChange={onChange}
                            value={this.state.toDate}
                            placeholder='To'
                            name='toDate'
                            type='date'
                          />
                        </div>
                          <button
                            disabled={searchDisabled}
                            onClick={onSubmit}
                            className='search-bar-button'
                          >
                         search now
                          </button>
                          </div>                 
               </div>

{/*  EXPERIENCES-MOBILE */}

<div className="exp-container container-fluid  text-white py-5" style={{backgroundColor:"rgb(255 255 255)"}}>
      <div className="row g-2 mb-4" style={{ overflowX: 'auto', whiteSpace: 'nowrap', scrollbarWidth: 'thin' }}>
        <div className="col-6">
          <img 
            src={img1} 
            alt="Friends dining together" 
            className="img-fluid rounded"
            style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
          />
        </div>
        <div className="col-6">
          <img 
            src={img2} 
            alt="Traveler at airport" 
            className="img-fluid rounded"
            style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
          />
        </div>
        <div className="col-4">
          <img 
            src={img3} 
            alt="Group selfie" 
            className="img-fluid rounded"
            style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
          />
        </div>
        <div className="col-6">
          <img 
            src={img4} 
            alt="Friends at station" 
            className="img-fluid rounded"
            style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
          />
        </div>
        <div className="col-2">
          <img 
            src={img6} 
            alt="Musical performance" 
            className="img-fluid rounded"
            style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
          />
        </div>
      </div>

      <div className=" mb-4">
        <h2 className="ml-5 h3 mb-2" 
        style={{color:"rgba(255, 117, 53, 1)", fontFamily:"Sora",textAlign:"center",fontWeight:"600"}}>
          Experiences</h2>
        <p className="h4 fw-bolder text-center"
        style={{
          // background: "linear-gradient(to bottom, #ffffff, #999999)",
          background: "linear-gradient(to bottom,rgba(253, 89, 12, 1),rgba(0, 0, 0, 1))",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontFamily:"Montserrat",
          fontSize:"1.5rem"
        }}
        >
          Meet travelers,<br />
          adventure, culture,<br />
          and new friendships<br />
          await!
        </p>
        <div className="row g-2 " style={{opacity:"10%", zIndex:"100",marginTop:"-5rem"}}>
            <div className="col-6">
              <img 
                src={img1} 
                alt="Friends dining together" 
                className="img-fluid rounded"
                style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
              />
            </div>
            <div className="col-4">
              <img 
                src={img2} 
                alt="Traveler at airport" 
                className="img-fluid rounded"
                style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
              />
            </div>
            <div className="col-2">
              <img 
                src={img3} 
                alt="Group selfie" 
                className="img-fluid rounded"
                style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
              />
            </div>
        </div>
      </div>

      <div className="row g-2">
        <div className="col-4">
          <img 
            src={img7} 
            alt="Solo traveler" 
            className="img-fluid rounded"
            style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
          />
        </div>
        <div className="col-5">
          <img 
            src={img8} 
            alt="Group dining" 
            className="img-fluid rounded"
            style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
          />
        </div>
        <div className="col-3">
          <img 
            src={img11} 
            alt="Friends gathering" 
            className="img-fluid rounded"
            style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
          />
        </div>
        <div className="col-6">
          <img 
            src={img5} 
            alt="Group celebration" 
            className="img-fluid rounded"
            style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
          />
        </div>
        <div className="col-6">
          <img 
            src={img10} 
            alt="Musical venue" 
            className="img-fluid rounded"
            style={{ width: '13.125rem', height: '7.375rem', objectFit: 'cover' }}
          />
        </div>
      </div>
    </div>

{/*  EXPERIENCES-MOBILE CLOSED */}

{/* locations */}
        <div className='main-container mt-5 mt-5' id="explore">
          <div className=''>
            <h2 className='text-red fw-bold text-uppercase text-center h2 fw-bold heading-font mb-3'>
              Properties
            </h2>
            <div
              style={{
                height: "5px",
                backgroundColor: "#ed452f",
                boxShadow: "0 4px 4px rgba(0, 0, 0, 0.5)",
                width: "100px",
                margin: "0 auto",
              }}
            />
          </div>
        </div>


        {/* Properties  */} 
    <div className="properties-main">
      <div className="property-list">
{ this.state.allHostels.slice(5,13).map((eachHostel) =>(
    <div key={eachHostel.id} class="single-property"   onClick={() => hostelRedirect(eachHostel.id)}>
    <div style={{width:"100%"}}>
    <h1 style={{fontSize:"18px" ,fontWeight:"800" ,marginLeft:"10px"}}>{eachHostel.hostel_name
  }</h1>
    </div>
       <img className="single-property-image" src={eachHostel.thumbnail}/>
      <div style={{ width:"100%"}}>
      <h2 style={{fontSize:"18px",fontWeight:"600",color:"gray",marginBottom:"0px"}}>{eachHostel.location}</h2>
      </div>
       <div className="single-property-button-div">
        <div style={{fontWeight:"700",fontSize:"18px"}}>Rs. {eachHostel.minimumRoomPrice || eachHostel.price}<span style={{color:"gray",fontWeight:"500" ,fontSize:"14px"}}>/Person </span></div>
        <button className="single-property-button">
          Book Now
        </button>
       </div>
       <div className="tags-div">
       {eachHostel.amenities.map((tag,index) =>(
            <div key={index} className="tag">
             {tag}
              </div>
          ))
        }
       </div>
    </div>
  ))
}
</div>
    </div>
{/* test cards  */}
     


<div style={{ width: "100%", overflow: "hidden",marginTop:"-3rem" }} className="mob-cards-cover" >
  <div
    className="horizontal-scroll-card-container"
    style={{
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
      gap: "2rem",
      padding: "0 1.5rem",
      scrollSnapType: "x mandatory",
      scrollbarWidth: "none",
    }}
  >
    {Array.from({ length: Math.ceil(this.state.allHostels.slice(5, 13).length / 2) }).map((_, index) => (
      <div
        key={index}
        className="column-container"
        style={{
          display: "grid",
          gridTemplateRows: "repeat(2, 1fr)", // Two rows
          gap: "2rem",
        }}
      >
        {this.state.allHostels.slice(5, 13).slice(index * 2, index * 2 + 2).map((eachHostel) => (
         <BookCardMobile key={eachHostel.id} eachHostel={eachHostel} hostelRedirect={hostelRedirect} />
        ))}
      </div>
    ))}
  </div>
</div>


        {/* Properties slider for small devices  */}
        <div className="property-slider">
        <Slider {...smallDeviceSlider}>  
        { this.state.allHostels.slice(5,13).map((eachHostel) =>(
    <div  style={{width:"280px" ,margin: "10px"}}  key={eachHostel.id} class="single-property-slider"   onClick={() => hostelRedirect(eachHostel.id)}>
    <div style={{width:"100%"}}>
    <h1 style={{fontSize:"18px" ,fontWeight:"800" ,marginLeft:"10px"}}>{eachHostel.hostel_name
  }</h1>
    </div>
       <img className="single-property-image" src={eachHostel.thumbnail}/>
      <div style={{ width:"100%"}}>
      <h2 style={{fontSize:"18px",fontWeight:"600",color:"gray",marginBottom:"0px"}}>{eachHostel.location}</h2>
      </div>
       <div className="single-property-button-div">
        <div style={{fontWeight:"700",fontSize:"18px"}}>Rs.{eachHostel.minimumRoomPrice || eachHostel.price}<span style={{color:"gray",fontWeight:"500" ,fontSize:"14px"}}>/Person </span></div>
        <button className="single-property-button">
          Book Now
        </button>
       </div>
       <div className="tags-div">
       {eachHostel.amenities.map((tag,index) =>(
            <div key={index} className="tag">
             {tag}
              </div>
          ))
        }
       </div>
    </div>
  ))
}
  </Slider>
          </div>
     {/* Destinations you shouldnt miss  */}
<div className="destination-div">
<div className="destination-inner-div">

  <div className="dest-main-left-div"> 
<div className="dest-text-div">
  <h1 className="dest-left-div-heading">
    <span style={{color:"#fd590c"}}>
      Destinations 
       </span>
      you shouldn't miss, <br/>
      <span style={{color:"#fd590c"}}>
      People 
       </span>
        you will
  </h1>
  {/* <h2 className="dest-left-div-sub-heading">
  Choose Your Destination and Contact Our Experts for the Best Deals!
  </h2> */}
  <div className="dest-buttons-div">
  <button onClick={()=>prevImage()} className="dest-button">
    <i class="bi bi-caret-left-fill"></i>
    </button>
    <button onClick={()=>nextImage()} className="dest-button">
    <i class="bi bi-caret-right-fill"></i>
    </button>
   
  </div>
  </div>
</div>
<div className="dest-images-div">
            {this.state.allHostels.slice(0,3).map((eachLocation,index) => {
              return (
                <div
                  key={eachLocation.id} className="single-dest-image" >
                  <div style={{ color: 'white', position: "relative", zIndex: 100 }}>
                    <a onClick={() => {
                      locationRedirect(this.state.allHostels[3 + this.state?.destImage]?.location);
                    }} style={{ cursor: "pointer" }}>
                      <img
                        src={this.state.allHostels[1 + this.state?.destImage]?.thumbnail}
                        alt={`image-${eachLocation.id}`}
                        className="dest-slider-left"
                      />
                      <h1 className="dest-image-heading-left">{this.state.allHostels[3 + this.state?.destImage]?.location} </h1>
                    </a>
                  </div>
                  <div style={{ color: 'white', position: "relative", zIndex: 100 }}>
                    <a onClick={() => {
                      locationRedirect(this.state.allHostels[2 + this.state?.destImage]?.location);
                    }} style={{ cursor: "pointer" }}>
                      <img
                        src={this.state.allHostels[2 + this.state?.destImage]?.thumbnail}
                        alt={`image-${eachLocation.id}`}
                        className="dest-slider-center-image"
                      />

                      <h1 className="dest-image-heading">{this.state.allHostels[2 + this.state?.destImage]?.location} </h1>
                    </a>
                  </div>
                  <div style={{ color: 'white', position: "relative", zIndex: 100 }}>
                    <a onClick={() => {
                      locationRedirect(this.state.allHostels[3 + this.state?.destImage]?.location);
                    }} style={{ cursor: "pointer" }}>
                      <img
                        src={this.state.allHostels[3 + this.state?.destImage]?.thumbnail}
                        alt={`image-${eachLocation.id}`}
                        className="dest-slider-right"
                      />
                      <h1 className="dest-image-heading-right">{this.state.allHostels[3 + this.state?.destImage]?.location} </h1>
                    </a>
                  </div>
                </div>

              );
            })}
</div>
</div>
</div>
        {/* testimonials */}
        <div className="testimonials-container">
<div className="testimonials-texts-div">
  <h1 className="testimonials-heading">Listen to What our  <br/> <span style={{color:"#fd590c"}}>  customers say </span></h1>
  <p className="testimonials-subheading">
  Our travelers have stories to tell and memories to share. From unforgettable stays to warm hospitality,<br/> hear how we’ve made their journeys specials. 
  </p>
</div>

<div className="users-reviews-cover">
<div className="users-reviews">
  <div className="single-user-review-orange">
    <h1 className="fs-4" style={{marginTop:"1rem"}}>Piyush</h1>
    <p style={{fontSize:"16px" ,width:"80%",textAlign:"center" ,fontFamily:"Lato"}}>Loved the cozy vibe of the hostel.</p>
  <div style={{display:"flex",gap:"8px" ,fontSize:"12px"}}>
  {
      Array(5).fill(0).map(() =>(
        <i class="bi bi-star-fill"></i>
      ))
    }
  </div>
  <img className="user-review-image"  src="https://media.istockphoto.com/id/1230749818/photo/portrait-of-smiling-male-owner-of-fashion-store-standing-in-front-of-clothing-display.jpg?s=612x612&w=0&k=20&c=xoWhF-hrQcbMEPDYncHiHF8HJX2YgmYt7T-KLCPZIpY="/>
  </div>
    <div className="single-user-review">
      <div className="user-review-text-div">

    <h1 className="fs-4" style={{color:"#fd590c"}}>Ayush</h1>
    <p className="user-review-para">An amazing stay! The staff was so helpful, and the facilities were super clean.</p>

  <div style={{display:"flex",gap:"8px" ,fontSize:"12px"}}>
     { Array(5).fill(0).map(() =>(
        <i class="bi bi-star-fill" style={{color:"#FFBE40"}}></i>
      ))}
       
  </div>
  </div>

  <img className="user-review-image"  src="https://www.zoho.com/inventory/case-study/images/maxime-loiselle.jpg"/>
  </div>

  <div className="single-user-review">
  <div className="user-review-text-div">

    <h1 className="fs-4" style={{color:"#fd590c"}}>Dilip</h1>
    <p className="user-review-para">  The rooms were spacious, and the view was incredible</p>
  <div style={{display:"flex",gap:"8px" ,fontSize:"12px"}}>
    {  Array(5).fill(0).map(() =>(
        <i class="bi bi-star-fill" style={{color:"#FFBE40"}}></i>
      ))}
  </div>
  </div>
  <img className="user-review-image"  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQBDox39ubeWBKcvnjRzPyPY2-z9AUIlNv_w&s"/>
  </div>

  <div>

  </div>
</div>
</div>
        </div>
        {/* Callin All creators */}
        <div className='bg-dark-o' style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
          <div className='py-5 d-flex flex-column flex-md-row justify-content-evenly align-items-center gap' style={{ width:"100rem"}}>
            <div className='d-flex flex-column justify-content-center banner-w'>
              <div className='h1 mx-4 text-white heading-font fw-bold'>
                Calling All Creators:
              </div>
              <div className='text-red h1 mx-4 heading-font fw-bold'>
                Travel’s On the House
              </div>
              <div className='text-text m-4 mb-3'>
                Have more than 1000 followers on Insta? Our Creators Travel Free
                program offers you a free stay at the best hostels in India. We
                want to collaborate with artists of all types — photographers,
                videographers, artists, musicians, DJs, bloggers, and so on.
              </div>
              <div>
                <Button onClick={()=>{
                  window.open("https://docs.google.com/forms/d/e/1FAIpQLSe-rcq8Xvz4HUDUMCEBewprZeI4v3ej4rTjqk9OIDnY4DDaOg/viewform", "_blank")
                }} className='primary-button m-4'>Learn more</Button>
              </div>
            </div>
            <div className='d-flex justify-content-center banner-w'>
              <div className='d-flex justify-content-center'>
                <img
                  className='hostel-discover-image'
                  src={Discover}
                  alt='discover'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='bg-white'>
          <div className='main-container py-5'>
            <div className='heading-font-2 text-red fw-bold text-uppercase h2 text-center'>
              Frequently asked questions
            </div>
            <div
              className='mt-3 mb-5'
              style={{
                height: "5px",
                backgroundColor: "#ed452f",
                boxShadow: "0 4px 4px rgba(0, 0, 0, 0.5)",
                width: "150px",
                margin: "0px auto",
              }}
            />
            {/* old accordian */}
            {/* <Accordion open={this.state.open} toggle={toggle}>
              <AccordionItem className='my-3 bg-transparent'>
                <AccordionHeader targetId='1' className='accordion-button-none'>
                  <p className='me-3 text-dark'>
                    I am an introvert travelling solo for the first time – how
                    do I ensure my first Hostel Experience is enjoyable and safe
                    ?
                  </p>
                  <i
                    class='bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1 '
                    style={{ opacity: 0.5 }}
                  ></i>
                </AccordionHeader>
                <AccordionBody className='text-dark' accordionId='1'>
                  At TheHostelCo, we have handpicked the coolest, most welcoming
                  hostels to give you a safe, fun, and unforgettable experience.
                  Perfect for solo travelers, our hostels are packed with
                  awesome activities and social spaces where you can meet fellow
                  wanderers and make connections that could last a lifetime! So,
                  dive into the Hostel & THC community vibes, join the fun, and
                  always respect personal space. Keep on Hostelling and make the
                  most of every adventure!
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='mb-3 bg-transparent border-none'>
                <AccordionHeader targetId='2' className='accordion-button-none'>
                  <p className='me-3 text-dark'>
                    Where is the HostelCo Network located ?
                  </p>
                  <i
                    class='bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1 '
                    style={{ opacity: 0.5 }}
                  ></i>
                </AccordionHeader>
                <AccordionBody className='text-dark' accordionId='2'>
                  TheHostelCo (THC) currently operates a vibrant network of
                  hostels across key backpacker destinations in India, including
                  Goa, Pondicherry, Rishikesh, Dharamshala, Bir, and more. We
                  are also rapidly expanding our presence across Southeast Asia
                  and other regions in India, aiming to offer a unique and
                  enriching experience for travelers in even more exciting
                  locations.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='mb-3 bg-transparent border-none'>
                <AccordionHeader targetId='3' className='accordion-button-none'>
                  <p className='me-3 text-dark'>
                    How do I choose the perfect Hostel for my first solo trip?
                  </p>
                  <i
                    class='bi bi-plus-circle-fill position-absolute end-0 ms-5 me-3 fs-1 '
                    style={{ color: red, opacity: 0.5 }}
                  ></i>
                </AccordionHeader>
                <AccordionBody className='text-dark' accordionId='3'>
                  To find the perfect hostel, begin by selecting your desired
                  destination. If you're flexible about the location, check out
                  the "Experience" halos. You can filter hostels based on
                  activities, amenities, and customer reviews—and voilà! You’ll
                  discover the hostels that are the best fit for your travel
                  style and preferences.
                </AccordionBody>
              </AccordionItem>
            </Accordion> */}

            <div className='faq-div'>
              <Accordion open={this.state.open} toggle={toggle} className='p-3'>
                <AccordionItem className='mb-3 rounded-pill shadow-sm'>
                  <AccordionHeader
                    targetId='1'
                    className='accordion-custom-header accordion-button-none'
                  >
                    <span className='w-100 px-3'>
                    Can I cancel the booking?
                    </span>
                    <i className='bi bi-plus-circle-fill plus-icon'></i>
                  </AccordionHeader>
                  <AccordionBody accordionId='1' className='text-secondary'>
                  As a default you cannot cancel the booking unless in extraneous circumstances - 
                  however if you do face any major issues upto 1 week before please email to palash@thehostelco.com
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem className='mb-3 rounded-pill shadow-sm '>
                  <AccordionHeader
                    targetId='2'
                    className='accordion-custom-header accordion-button-none'
                  >
                    <span className='w-100 px-3'>
                    Anything I should keep in mind?
                    </span>
                    <i className='bi bi-plus-circle-fill plus-icon'></i>
                  </AccordionHeader>
                  <AccordionBody accordionId='2' className='text-secondary'>
                  Hostels are of very different types - Chain Hostels/ Luxury Hostels / Party Hostel /Hippie Hostels etc - 
                  with each having trade offs of Pricing vs Vibe vs Location vs Facilities vs Cleanliness. 
                  Make sure you investigate the hostel thoroughly before making your booking and remember to keep an open mind.

                  </AccordionBody>
                </AccordionItem>

                <AccordionItem className='mb-3 rounded-pill shadow-sm '>
                  <AccordionHeader
                    targetId='3'
                    className='accordion-custom-header accordion-button-none'
                  >
                    <span className='w-100 px-3'>
                    How much Do I need to pay to hold my booking?
                    </span>
                    <i className='bi bi-plus-circle-fill plus-icon'></i>
                  </AccordionHeader>
                  <AccordionBody accordionId='3' className='text-secondary'>
                  Each hostel has its own pre-decided amount that needs to be paid to hold the booking.
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem className='mb-3 rounded-pill shadow-sm'>
                  <AccordionHeader
                    targetId='4'
                    className='accordion-custom-header accordion-button-none'
                  >
                    <span className='w-100 px-3'>
                    If I face any issue what is the resolution?
                    </span>
                    <i className='bi bi-plus-circle-fill plus-icon'></i>
                  </AccordionHeader>
                  <AccordionBody accordionId='4' className='text-secondary'>
                  Please reach out to +91 9673203092 Incase you face any issue during the reservation process or your stay.
                  </AccordionBody>
                </AccordionItem>
              </Accordion>

              <div className='faq-img-div'>
                <img src={faq} alt='faq' className='faq-img' />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default LandingPage;