const BookCardMobile = ({ eachHostel, hostelRedirect }) => {
    return (
        <div
            className="card text-bg-dark position-relative"
            style={{
                borderRadius: "1rem",
                overflow: "hidden",
                minWidth: "12rem",
                width:"12rem",
                height: "11rem",
                fontFamily: "Poppins",
            }}
        >
            <img
                src={eachHostel.thumbnail}
                className="card-img"
                alt="Dormitory Image"
                style={{ objectFit: "cover", height: "100%" }}
            />
            <div onClick={() => { hostelRedirect(eachHostel.id) }}
                className="card-img-overlay d-flex flex-column justify-content-end"
                style={{
                    padding: "0rem",
                }}
            >
            <div style={{background: "linear-gradient(rgba(61,61,61,0.6), rgba(0,0,0,0.6))",padding:"0.5rem",
                padding:"0.1rem 0.5rem 0.5rem 0.5rem"
            }}>
                <div>
                <h5 className="card-text text-white fw-semibold"
                    style={{
                        padding:"0rem 0.3rem",
                        fontSize: "12px",
                        textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                    }}
                > {eachHostel.hostel_name} </h5>

                <div className="d-flex justify-content-between">
                    <div>
                    <p className="card-text text-white fw-medium"
                        style={{
                            padding:"0rem 0.3rem",
                            fontSize: "10px",
                            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                        }}
                    > Rs.{eachHostel.minimumRoomPrice || eachHostel.price}
                    </p>
                    </div>
                    <button className=" bg-transparent fw-bold text-white" 
                    style={{ border: "none" ,fontSize:"10px",textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",}}>
                        Book Now</button>
                </div>
                    </div>

                <div className="d-flex flex-wrap" >
                    {eachHostel.amenities.map((tag, index) => (
                        <div key={index} className="tag text-dark bg-light"
                            style={{ borderRadius: "15px", width: "fit-content", fontSize: "5px", padding: "1px 3px", alignSelf: "center" }} >
                            {tag}
                        </div>
                    ))
                    }
                    
                </div>
                {/* <div className="d-flex justify-content-end ">
                    <button className=" bg-transparent fw-bold text-white" 
                    style={{ border: "none" ,fontSize:"10px",textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",}}>
                        Book Now</button>
                </div> */}
            </div>

            </div>
        </div>
    );
};

export default BookCardMobile